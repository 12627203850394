import React from "react";
import { graphql } from "gatsby";
import { device } from "../device";
import Img from "gatsby-image";
import styled from "styled-components";
import Layout from "../components/Layout";
import Quill from "../components/Quill";

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Box = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
`;

const List = styled.ul`
  margin: auto;
  padding-right: 10vw;
  text-align: center;
  max-width: 48em;
`;

const Item = styled.li`
  font-size: 2rem;
  &:not(:last-of-type) {
    margin-bottom: 48px;
  }
  @media ${device.tablet} {
    font-size: 2.25rem;
  }
`;

const StyledLink = styled.a`
  color: red;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Basteleur", sans-serif;
`;

const body = {
  links: [
    {
      id: 1,
      text: "Images Festival Catalogue | Yasmin Nurming-Por, 2021",
      url: "IF21_Catalogue_GlobalCows.pdf",
    },
    {
      id: 2,
      text: "Chapter Two | Sonia D'Alto, 2020",
      url: "Global_Cows_2020_Second_Chapter_Sonia_DAlto.pdf",
    },
    {
      id: 3,
      text: "Chapter One | Sonia D'Alto, 2019",
      url: "Global_Cows_First_Chapter_Sonia_DAlto.pdf",
    },
  ],
};

const WritingsPage = ({ data }) => {
  return (
    <Layout>
      <Container>
        <StyledImg fluid={data.sexyCowLegs.fluid} />
      </Container>
      <Box>
        <List>
          {body.links.map((link) => (
            <Item key={link.id}>
              <Quill />{" "}
              <StyledLink href={"/" + link.url}>{link.text}</StyledLink>
            </Item>
          ))}
        </List>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query {
    sexyCowLegs: imageSharp(
      fluid: { originalName: { eq: "sexy-cow-legs.jpeg" } }
    ) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default WritingsPage;
