import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  transform: rotate(220deg) scaleX(-1);
`;

const Quill = () => {
  return (
    <Svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="50"
      viewBox="0 0 1280.000000 640.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
        fill="red"
        stroke="none"
      >
        <path
          d="M1023 5367 c2 -6 16 -13 30 -15 18 -2 27 -9 27 -21 0 -14 -6 -17 -31
-13 -20 3 -34 -1 -42 -12 -11 -16 -16 -14 -55 13 -76 56 -124 36 -88 -35 11
-19 12 -33 5 -46 -11 -21 -10 -21 -45 -8 -19 8 -56 7 -126 -2 -96 -12 -99 -11
-113 9 -18 25 -98 58 -120 49 -14 -5 -13 -9 4 -28 29 -31 28 -55 -1 -50 -13 2
-17 -3 -15 -19 1 -16 -4 -24 -19 -28 -20 -6 -17 -12 40 -90 79 -109 121 -142
183 -148 44 -5 51 -9 72 -45 12 -22 43 -54 68 -71 74 -51 110 -85 123 -117 10
-23 23 -32 65 -45 30 -8 61 -22 69 -31 16 -16 21 -46 10 -57 -4 -4 -48 15 -98
40 -64 33 -111 67 -157 113 -37 36 -69 72 -71 80 -3 8 -9 12 -13 8 -4 -4 3
-23 15 -43 16 -25 19 -40 12 -47 -14 -14 -26 -5 -73 58 -40 54 -66 71 -54 35
11 -34 -12 -32 -88 9 -91 48 -156 65 -171 45 -6 -7 -29 -19 -51 -26 -33 -11
-36 -14 -17 -17 13 -2 37 1 54 7 53 19 83 13 174 -35 100 -53 144 -89 144
-117 0 -17 5 -19 41 -13 44 7 69 -5 69 -33 0 -11 -21 -24 -67 -40 -77 -27
-148 -62 -178 -86 -20 -16 -19 -16 5 -9 14 3 68 26 120 50 154 70 224 75 279
19 l26 -26 -25 -6 c-14 -3 -124 -57 -245 -118 -121 -62 -239 -119 -263 -126
-76 -22 -38 -20 79 5 63 14 177 34 254 45 77 12 185 33 240 47 108 28 194 29
221 2 13 -13 0 -17 -113 -32 -152 -21 -391 -68 -517 -102 -50 -13 -105 -24
-123 -24 -17 0 -35 -4 -38 -10 -7 -12 -4 -12 109 -10 76 1 94 5 120 25 46 34
88 44 226 55 69 5 178 15 242 22 130 13 236 3 310 -30 l41 -17 -206 -48 c-113
-27 -248 -53 -299 -59 -51 -5 -101 -14 -110 -19 -10 -5 -52 -9 -93 -9 -54 0
-74 -3 -69 -11 4 -8 29 -9 75 -5 43 5 76 3 89 -4 29 -15 25 -30 -9 -30 -29 0
-57 -21 -44 -33 10 -10 73 -8 117 4 39 11 86 5 86 -10 0 -5 -20 -14 -45 -21
-116 -32 -44 -60 156 -60 119 0 151 -12 71 -26 -20 -4 -44 -10 -52 -15 -20
-11 104 -11 152 0 30 7 40 5 53 -10 10 -10 14 -22 11 -27 -4 -6 34 -11 98 -14
113 -3 135 -12 117 -46 -10 -19 -7 -19 93 -18 114 1 144 -10 125 -46 -10 -19
-7 -19 113 -16 87 2 133 -1 156 -11 47 -20 40 -35 -13 -28 -47 7 -95 -5 -95
-23 0 -6 38 -10 100 -10 93 0 100 -1 100 -20 0 -32 32 -40 162 -39 116 2 168
-8 168 -29 0 -4 -17 -8 -39 -8 -49 -2 -90 -22 -70 -35 21 -13 98 -11 244 6
304 36 418 37 500 4 l30 -12 -25 -7 c-14 -5 -112 -8 -219 -9 -200 -1 -257 -9
-287 -43 -16 -17 -14 -18 57 -18 91 0 107 -8 91 -44 l-12 -26 54 0 c34 0 96
-13 164 -35 61 -19 145 -37 186 -41 62 -6 74 -9 65 -20 -8 -10 -8 -15 2 -18
233 -66 557 -134 680 -142 94 -6 106 -9 115 -28 7 -16 19 -22 54 -24 72 -4 89
-10 104 -41 19 -35 51 -48 146 -57 55 -5 98 -17 160 -45 99 -44 169 -63 206
-56 22 4 28 0 36 -24 10 -28 11 -29 84 -29 68 0 83 -4 172 -46 151 -72 341
-147 667 -264 403 -145 512 -188 700 -280 144 -69 168 -78 230 -82 84 -4 142
-30 170 -75 16 -26 30 -34 75 -43 154 -30 184 -40 220 -75 50 -48 105 -55 505
-65 209 -5 359 -13 390 -21 l50 -12 -64 -14 c-117 -24 -363 -53 -506 -60
l-140 -7 90 -23 c329 -84 399 -84 670 4 169 54 250 50 347 -19 23 -16 26 -24
21 -51 -3 -18 -7 -34 -9 -36 -1 -1 -66 -16 -144 -32 -269 -56 -326 -99 -104
-79 176 17 176 0 -1 -120 -55 -37 -111 -80 -125 -95 -20 -22 -8 -17 65 25 170
99 241 165 247 230 2 27 7 35 23 35 27 0 23 -29 -21 -138 -30 -74 -35 -98 -33
-156 2 -65 0 -71 -33 -113 -40 -48 -78 -115 -78 -137 0 -26 32 13 90 109 49
81 127 170 151 170 12 0 0 -56 -27 -122 -20 -51 -29 -93 -25 -123 1 -5 34 69
74 165 72 173 133 290 182 349 l26 30 66 -25 c37 -14 101 -33 142 -43 165 -40
158 -36 153 -76 -3 -21 0 -35 7 -35 23 0 365 122 419 149 100 51 99 50 72 106
-22 46 -22 49 -5 61 15 11 21 10 44 -7 19 -14 53 -22 124 -28 148 -14 200 -56
131 -106 -15 -11 -44 -35 -63 -53 l-36 -34 28 5 c42 7 42 -9 0 -48 -20 -19
-34 -37 -30 -41 3 -4 34 19 67 50 63 58 100 83 100 68 0 -5 -9 -17 -20 -27
-12 -11 -37 -46 -57 -79 -47 -80 -68 -102 -130 -142 -56 -36 -78 -66 -69 -91
5 -13 13 -10 44 13 93 69 217 199 303 317 47 64 48 67 25 67 -13 0 -29 -7 -36
-15 -7 -8 -15 -15 -19 -15 -10 0 -31 61 -25 71 8 14 81 10 120 -6 30 -12 34
-18 29 -37 -13 -44 -58 -112 -134 -202 -105 -125 -136 -167 -119 -161 8 3 21
-1 28 -9 11 -14 19 -7 60 52 75 107 80 108 79 21 0 -41 -4 -109 -7 -152 -4
-42 -6 -77 -4 -77 3 0 20 13 38 30 19 16 46 33 60 36 24 6 26 10 20 43 -10 62
13 159 66 291 28 69 52 126 53 127 1 2 89 -6 196 -17 211 -21 1145 -99 1579
-131 183 -14 440 -6 562 16 81 14 223 56 255 74 22 12 -31 69 -87 95 -41 19
-71 21 -385 27 -432 9 -556 20 -1305 114 -239 30 -498 62 -575 70 -128 14
-142 17 -162 40 -19 21 -23 38 -23 91 0 36 -7 94 -16 130 -16 62 -19 66 -63
88 -25 13 -88 56 -138 95 l-93 70 -29 -16 c-28 -17 -30 -16 -72 12 -24 16
-102 52 -174 81 -126 51 -130 53 -138 88 -6 21 -24 49 -47 70 -24 21 -52 64
-73 109 -34 73 -53 92 -82 81 -19 -8 -19 -7 10 -50 38 -56 45 -93 60 -319 14
-219 22 -259 62 -313 30 -40 16 -41 -55 -6 -28 14 -73 49 -100 78 -46 51 -71
90 -177 273 -56 98 -120 172 -178 206 -23 14 -99 44 -169 67 -96 31 -140 51
-178 81 -27 22 -50 44 -50 49 0 6 -11 10 -24 10 -52 0 -71 16 -86 69 -9 33
-19 51 -29 51 -28 1 -98 43 -167 100 l-66 55 -116 0 c-139 0 -192 15 -289 81
-66 46 -72 35 -11 -23 49 -47 144 -90 271 -123 62 -17 117 -30 122 -30 6 0
-13 14 -42 30 -65 37 -73 50 -33 50 49 0 109 -35 199 -115 46 -42 94 -78 106
-81 13 -3 30 -23 43 -48 12 -25 47 -66 84 -97 152 -130 168 -158 168 -294 0
-89 12 -146 35 -165 9 -7 17 -11 19 -9 2 2 -4 36 -14 74 -20 73 -26 195 -11
195 11 0 18 -28 26 -113 5 -43 14 -76 26 -90 l18 -22 1 26 c0 15 -7 62 -16
104 -20 97 -8 117 31 50 16 -27 32 -75 38 -116 10 -61 14 -69 34 -69 12 0 25
-4 28 -10 3 -5 13 -10 22 -10 12 0 14 7 8 37 -4 23 -3 34 3 28 10 -12 69 -167
78 -208 6 -26 4 -28 -17 -25 -17 2 -26 13 -34 40 -15 55 -29 60 -22 7 6 -41 4
-47 -14 -52 -26 -7 -49 18 -49 52 0 15 -7 36 -15 47 -13 18 -14 15 -15 -30 l0
-49 -67 6 c-142 11 -317 92 -412 191 -38 39 -51 44 -51 21 0 -52 -49 30 -123
203 -44 105 -91 192 -102 192 -5 0 19 -63 58 -150 34 -77 59 -158 54 -173 -2
-7 -10 0 -17 16 -8 15 -21 27 -32 27 -15 0 -17 -5 -12 -27 4 -16 11 -36 15
-45 22 -42 4 -38 -40 9 -31 34 -57 53 -72 53 -21 0 -21 -1 -5 -19 19 -21 18
-72 -1 -78 -7 -3 -62 95 -139 249 -140 280 -197 374 -244 401 -17 11 -33 17
-35 15 -3 -2 6 -20 18 -39 13 -19 45 -74 71 -124 l47 -90 -36 35 c-20 19 -62
86 -95 150 -33 63 -68 128 -79 145 l-21 30 21 7 c12 5 23 17 26 28 8 32 29 24
92 -34 70 -64 136 -109 151 -104 7 2 -11 18 -38 36 -113 73 -201 204 -225 337
-9 50 -18 71 -29 73 -11 2 -18 -5 -20 -23 -2 -14 -7 -26 -13 -27 -14 -2 -22
44 -24 130 0 40 -5 72 -11 72 -6 0 -31 26 -56 59 -36 47 -77 81 -98 81 -2 0
12 -39 30 -88 58 -156 93 -321 94 -444 0 -32 -3 -58 -7 -58 -24 0 -83 58 -140
138 -37 52 -79 100 -96 108 -29 16 -29 15 11 -32 22 -27 66 -96 98 -154 67
-121 114 -194 121 -186 3 3 -5 21 -17 41 -13 21 -19 42 -15 53 13 33 85 -72
199 -294 54 -105 142 -232 153 -221 2 3 -29 59 -70 124 -129 204 -158 266
-171 353 -7 43 -5 42 32 -36 58 -120 109 -205 277 -459 186 -283 172 -260 147
-246 -27 14 -64 59 -101 123 -48 84 -77 115 -92 101 -10 -10 -8 -23 11 -62 19
-42 21 -53 10 -66 -18 -21 -32 -7 -68 69 -32 66 -45 82 -62 71 -7 -4 -5 -14 5
-28 17 -25 20 -73 5 -82 -17 -11 -34 10 -54 64 -17 49 -41 79 -53 68 -3 -4 11
-45 31 -93 21 -47 35 -88 33 -90 -3 -3 -21 14 -40 37 -43 49 -99 156 -157 294
-41 99 -88 190 -95 183 -2 -2 4 -36 14 -76 11 -40 15 -72 11 -72 -22 0 -51 60
-70 145 -26 115 -45 137 -38 44 8 -96 69 -203 142 -248 26 -17 34 -29 39 -61
3 -22 18 -67 35 -100 16 -33 27 -60 25 -60 -27 0 -73 45 -119 117 -31 47 -58
83 -60 80 -10 -10 16 -83 50 -138 19 -31 37 -67 41 -80 7 -22 4 -20 -30 11
-47 45 -97 113 -152 208 -43 73 -45 75 -39 37 4 -22 29 -84 56 -137 28 -54 50
-103 50 -110 0 -7 -18 6 -41 27 -28 28 -61 82 -107 175 -48 98 -67 129 -70
112 -5 -31 14 -86 54 -155 38 -64 52 -107 36 -107 -16 0 -102 101 -143 169
-25 42 -39 56 -43 46 -8 -23 4 -74 31 -128 13 -27 23 -58 21 -69 -6 -40 -55
18 -109 130 -50 103 -71 123 -50 46 18 -65 -7 -59 -29 6 -17 52 -30 60 -30 21
0 -35 -6 -38 -32 -14 -17 15 -18 14 -18 -21 0 -41 -28 -98 -45 -93 -5 2 -27
28 -48 58 -20 30 -48 58 -60 63 -16 6 -31 32 -56 100 -46 124 -139 305 -141
276 0 -3 24 -67 54 -141 56 -142 106 -246 137 -291 11 -14 19 -28 19 -30 0 -8
-95 8 -117 20 -13 7 -25 25 -28 40 -20 95 -50 166 -99 231 -37 50 -32 22 13
-67 44 -88 62 -143 54 -171 -5 -19 -7 -19 -33 -6 -43 22 -84 78 -126 173 -38
87 -91 182 -101 182 -13 0 12 -62 56 -140 51 -89 67 -136 52 -158 -6 -10 -32
31 -85 135 -42 81 -87 159 -99 173 -26 29 -64 60 -55 45 3 -5 31 -49 61 -98
50 -80 117 -220 117 -246 0 -14 -55 -14 -81 0 -11 5 -33 28 -49 50 l-29 40 -3
-28 c-11 -99 -128 55 -368 483 -62 109 -109 186 -105 169 16 -74 75 -187 183
-352 114 -175 181 -297 170 -309 -4 -3 -15 -1 -26 5 -12 6 -30 11 -41 11 -15
0 -29 16 -52 63 -17 34 -47 89 -67 122 -49 83 -205 306 -210 300 -10 -9 43
-98 113 -191 82 -108 110 -158 121 -218 7 -38 6 -38 -17 -25 -50 26 -169 160
-264 296 -54 78 -101 141 -103 139 -12 -13 48 -108 141 -224 59 -73 107 -137
107 -142 0 -4 3 -16 7 -26 5 -14 2 -16 -18 -10 -13 4 -84 23 -156 41 -122 31
-233 77 -233 96 0 4 4 11 10 14 26 16 8 55 -51 112 -77 74 -105 82 -33 10 56
-56 66 -86 41 -120 -11 -16 -19 -16 -64 -7 -73 16 -127 55 -233 170 -51 55
-102 105 -114 112 -17 9 -16 6 7 -19 31 -33 36 -77 10 -92 -23 -13 -10 -25 42
-41 44 -14 65 -27 65 -42 0 -6 -6 -6 -14 1 -9 8 -32 10 -63 6 -47 -7 -49 -6
-91 39 -24 25 -49 46 -55 46 -7 0 -1 -9 12 -19 40 -30 16 -45 -30 -19 -54 30
-121 104 -153 166 -33 65 -33 74 -1 62 22 -8 25 -7 25 11 0 13 -11 25 -35 35
-42 17 -58 41 -80 119 -20 68 -51 135 -64 135 -11 0 3 -74 30 -165 25 -85 71
-169 144 -266 30 -40 53 -74 51 -77 -14 -13 -100 45 -159 107 -38 39 -71 71
-73 71 -10 0 26 -57 50 -79 23 -21 36 -61 21 -61 -22 0 -68 38 -104 84 -38 52
-61 69 -61 47 0 -4 9 -20 20 -34 19 -24 27 -57 14 -57 -14 0 -80 65 -101 99
-13 20 -23 52 -23 72 0 24 -16 66 -44 120 -42 81 -64 141 -121 332 -16 53 -30
85 -37 81 -15 -9 -6 -40 67 -244 36 -101 73 -213 81 -249 9 -37 28 -82 45
-105 53 -73 33 -92 -40 -38 -38 28 -61 76 -61 125 0 20 -7 41 -18 50 -10 9
-35 52 -57 97 -39 80 -69 113 -39 43 43 -102 54 -137 54 -172 0 -26 7 -44 21
-57 29 -26 51 -71 39 -79 -31 -19 -88 52 -130 163 -18 45 -35 82 -40 82 -4 0
-11 -12 -14 -26 -5 -19 2 -45 24 -91 17 -36 29 -67 26 -71 -10 -10 -54 16 -71
42 -9 14 -27 56 -40 95 -13 38 -34 89 -46 113 -18 34 -24 39 -27 23 -11 -58
-44 -30 -88 75 -38 91 -75 163 -80 158 -12 -13 44 -151 87 -216 148 -218 179
-272 160 -272 -18 0 -114 95 -150 149 -21 31 -71 119 -111 196 -80 154 -141
255 -153 255 -15 0 5 -95 33 -155 15 -33 65 -112 110 -175 85 -119 139 -215
127 -226 -11 -11 -111 81 -176 161 -33 42 -95 132 -137 200 -43 69 -80 123
-82 120 -11 -11 73 -157 149 -260 78 -106 125 -183 116 -191 -2 -2 -24 9 -48
26 -52 34 -86 78 -157 205 -48 86 -102 152 -102 126 0 -26 -39 10 -75 69 -37
61 -64 82 -43 33 23 -52 75 -128 161 -233 122 -148 144 -201 62 -150 -40 25
-73 65 -105 129 -12 25 -46 66 -75 93 -40 37 -67 76 -114 167 -69 132 -90 165
-91 140 0 -34 84 -183 194 -343 64 -93 115 -170 114 -171 -2 -2 -27 12 -56 31
-54 35 -89 79 -98 125 -4 19 -14 29 -34 32 -40 9 -85 51 -145 137 -50 70 -95
121 -95 107 0 -4 18 -30 40 -59 22 -29 45 -66 50 -83 6 -16 25 -39 42 -50 32
-20 80 -89 92 -131 6 -19 3 -21 -21 -16 -18 3 -57 35 -113 93 -93 96 -127 119
-61 40 69 -83 45 -113 -33 -41 -48 45 -60 49 -51 20 8 -25 -13 -27 -55 -5 -33
17 -50 43 -50 74 0 15 -6 21 -20 21 -54 0 -116 58 -207 195 -33 50 -71 99 -83
110 l-23 20 24 -44 c13 -25 70 -108 125 -185 56 -78 100 -143 98 -145 -10 -11
-88 55 -150 127 -38 44 -73 81 -77 81 -12 1 12 -39 50 -86 19 -23 33 -50 31
-59 -2 -13 -15 -6 -57 32 -73 65 -116 135 -191 306 -92 211 -127 269 -195 328
l-29 25 17 -25 c71 -102 104 -163 158 -285 33 -77 81 -182 105 -234 25 -52 43
-96 41 -98 -6 -5 -53 41 -100 96 -20 25 -37 38 -37 31 0 -8 12 -26 26 -41 16
-17 24 -34 20 -44 -5 -14 -8 -14 -37 1 -59 30 -130 108 -215 234 -82 121 -144
202 -144 187 0 -4 20 -46 45 -93 25 -48 44 -89 41 -92 -3 -2 -17 3 -31 12 -39
25 -30 3 25 -63 50 -60 63 -88 35 -77 -41 16 -125 78 -141 105 -14 23 -31 35
-70 46 -65 19 -133 69 -220 165 -38 41 -80 84 -93 94 -13 11 -36 48 -52 82
-22 49 -34 65 -55 70 -28 7 -44 -6 -44 -40 0 -10 15 -53 34 -95 58 -127 58
-167 1 -100 -54 65 -93 172 -110 304 -6 47 -9 52 -57 80 -50 30 -108 87 -108
106 0 12 68 -22 140 -71 65 -44 158 -134 335 -324 124 -134 130 -139 133 -110
6 50 18 48 65 -13 52 -67 117 -132 117 -116 0 20 -66 101 -181 220 -89 93
-125 139 -153 194 -20 40 -34 74 -31 77 2 3 21 -8 41 -24 59 -47 74 -57 79
-52 3 3 -47 53 -110 113 -100 93 -175 179 -175 200 0 13 94 -61 156 -123 35
-35 115 -123 178 -195 121 -141 187 -208 168 -171 -6 11 -18 27 -27 34 -22 19
-47 68 -40 80 3 6 23 -10 44 -34 21 -24 76 -76 122 -116 46 -40 99 -91 117
-113 55 -69 57 -66 13 18 -22 44 -92 147 -153 229 l-113 148 -74 19 c-41 11
-85 26 -97 34 -25 16 -49 53 -41 62 12 11 71 -11 145 -56 98 -60 144 -76 171
-61 18 9 26 4 63 -36 24 -26 88 -84 143 -129 121 -100 202 -184 239 -247 15
-27 33 -48 40 -48 18 0 79 -67 119 -132 35 -55 81 -97 94 -85 3 4 -17 32 -44
64 -27 32 -112 139 -188 238 -185 241 -210 268 -259 277 -51 10 -104 46 -145
97 -34 43 -31 52 7 25 12 -8 36 -19 52 -24 26 -7 30 -6 30 11 0 12 -14 28 -35
40 -19 12 -35 28 -35 36 0 24 64 -33 170 -150 52 -57 97 -103 99 -101 2 2 -11
26 -29 54 -29 45 -31 54 -21 77 6 14 10 27 9 28 -2 1 -30 16 -63 34 -33 17
-128 82 -212 145 -156 117 -248 170 -318 181 l-40 6 36 -20 c19 -11 41 -27 48
-35 12 -14 9 -16 -21 -16 -50 0 -93 17 -181 72 -42 26 -94 55 -115 62 -51 19
-265 30 -259 13z m396 -127 c33 -16 104 -57 158 -91 l98 -61 -50 4 c-89 8
-216 79 -265 149 -27 37 -14 37 59 -1z m-782 -155 c31 -49 66 -105 80 -122 13
-18 20 -33 15 -33 -17 0 -79 77 -122 151 -24 41 -52 90 -63 109 l-20 35 27
-25 c15 -14 53 -65 83 -115z m538 125 c3 -5 17 -10 31 -10 19 0 24 -5 24 -25
0 -36 -3 -40 -20 -37 -17 3 -70 58 -70 72 0 13 27 13 35 0z m-187 -14 c15 -8
38 -26 52 -41 14 -15 43 -41 64 -56 38 -28 38 -29 12 -29 -15 0 -46 10 -69 21
-51 26 -147 98 -147 110 0 14 58 11 88 -5z m192 -76 c25 -22 62 -50 82 -63 31
-18 36 -26 31 -47 -3 -14 -7 -26 -9 -28 -7 -9 -53 32 -89 79 -22 28 -60 65
-85 81 l-45 30 35 -6 c19 -4 54 -24 80 -46z m249 21 c28 -28 55 -71 48 -77 -3
-3 -26 14 -50 40 -41 41 -47 56 -25 56 4 0 17 -9 27 -19z m-665 -131 c40 -75
87 -180 81 -180 -14 0 -185 293 -185 315 1 19 70 -70 104 -135z m10 108 c18
-8 42 -44 81 -119 30 -59 68 -118 85 -132 22 -19 30 -35 30 -59 0 -18 12 -51
25 -73 25 -40 33 -85 16 -85 -20 0 -73 55 -96 100 -13 25 -36 84 -51 131 -40
124 -59 163 -99 208 -38 42 -35 50 9 29z m156 -73 c0 -25 -4 -45 -8 -45 -12 0
-61 77 -54 84 3 3 18 6 34 6 27 0 28 -2 28 -45z m188 -97 c5 -7 12 -24 14 -38
2 -20 -1 -24 -17 -22 -24 5 -35 19 -35 50 0 24 21 30 38 10z m-44 -97 c18 -32
48 -98 66 -147 45 -123 87 -203 124 -238 30 -28 30 -29 6 -21 -73 22 -143 83
-183 162 -37 71 -83 324 -57 308 5 -3 25 -32 44 -64z m246 -100 c0 -30 137
-174 288 -305 95 -81 172 -151 172 -156 0 -4 -7 -13 -15 -20 -8 -7 -15 -18
-15 -25 0 -28 290 -135 747 -276 180 -55 585 -185 898 -289 314 -103 611 -200
660 -216 50 -15 142 -51 205 -80 104 -48 141 -59 380 -110 308 -65 435 -99
730 -193 306 -98 892 -271 1240 -367 158 -44 952 -227 1250 -289 123 -26 338
-80 478 -121 335 -99 403 -114 757 -174 481 -82 740 -138 1010 -220 322 -97
439 -114 1075 -155 240 -15 275 -18 440 -37 75 -8 107 -17 150 -41 39 -23 67
-31 102 -31 26 0 48 -3 48 -7 0 -14 -67 -37 -125 -42 -51 -5 -57 -4 -42 7 9 7
17 15 17 18 0 3 -38 8 -85 12 -47 4 -120 13 -163 21 -42 7 -79 12 -81 10 -10
-10 15 -26 56 -36 37 -10 42 -14 28 -22 -26 -14 -104 0 -212 39 -112 41 -240
58 -493 69 -275 11 -383 30 -493 86 l-58 30 -52 -18 c-41 -14 -82 -18 -192
-18 -158 1 -200 7 -510 75 -121 27 -391 85 -600 130 -724 154 -1038 227 -1295
301 -164 47 -472 150 -467 156 3 2 55 -3 115 -12 61 -10 116 -15 124 -12 22 8
-93 55 -166 68 -52 9 -85 9 -139 0 l-70 -12 -81 40 c-45 22 -135 54 -201 71
-66 17 -208 61 -315 98 -186 63 -200 66 -295 67 -93 2 -110 5 -230 49 -71 26
-173 63 -225 81 -90 32 -559 168 -996 289 -404 112 -534 155 -1397 458 -296
104 -650 222 -785 263 -331 99 -452 143 -607 220 -127 63 -288 172 -279 187 9
15 35 8 70 -18 19 -15 37 -24 40 -21 3 3 -43 38 -103 79 -177 120 -262 222
-298 357 -7 28 -9 51 -4 51 5 0 9 -4 9 -9z m46 -262 c42 -28 154 -145 154
-160 0 -20 -24 -18 -70 8 -41 23 -50 33 -50 55 0 23 -9 33 -52 57 -78 44 -98
60 -98 81 0 18 2 18 37 3 21 -8 56 -28 79 -44z m-251 -45 c-11 -8 -42 -21 -70
-29 -53 -16 -177 -35 -184 -29 -6 6 20 34 43 46 31 17 83 26 161 27 65 0 68
-1 50 -15z m838 -222 c27 -24 22 -43 -10 -36 -47 10 -97 26 -118 38 -18 10
-16 11 20 17 49 7 85 1 108 -19z m-493 -63 c-77 -23 -223 -49 -265 -48 -29 0
-28 1 15 21 66 29 155 46 240 46 l75 0 -65 -19z m174 -41 c21 -11 21 -12 -7
-19 -15 -4 -82 -14 -150 -23 -67 -9 -132 -19 -144 -22 -13 -4 -23 -4 -23 0 0
9 116 65 158 76 44 11 135 4 166 -12z m80 -163 c-13 -10 -42 -14 -89 -14 l-70
1 40 13 c22 8 62 14 89 14 46 1 48 0 30 -14z m474 -44 c23 -7 42 -16 42 -21 0
-17 -38 -30 -92 -30 -43 -1 -50 -3 -34 -12 12 -7 44 -8 87 -4 45 4 69 3 69 -4
0 -6 -7 -13 -15 -15 -8 -3 -23 -8 -32 -12 -10 -3 56 -12 147 -19 91 -6 177
-15 192 -19 50 -13 18 -25 -64 -25 -135 0 -77 -14 85 -21 152 -6 207 -18 207
-46 0 -21 -320 -22 -487 -1 -51 6 -93 15 -93 20 0 9 11 11 55 9 28 -1 29 -1
12 13 -10 8 -40 17 -66 21 -60 8 -75 18 -68 44 5 19 1 20 -101 23 -73 2 -107
7 -110 16 -2 8 8 12 30 12 70 1 1 19 -97 25 -55 3 -111 8 -124 11 -22 5 -23 6
-8 22 21 20 136 29 192 13 28 -7 42 -7 52 1 32 26 120 26 221 -1z m811 -287
c24 -7 23 -8 -14 -21 -38 -13 -92 -18 -317 -28 l-118 -5 0 36 0 37 213 -6
c116 -3 223 -9 236 -13z m322 -153 c-12 -22 -62 -47 -114 -56 -44 -9 -297 14
-297 26 0 4 12 17 26 28 25 20 40 21 211 21 176 0 183 -1 174 -19z m179 -88
c66 -12 1 -22 -149 -22 -106 -1 -142 2 -134 10 27 27 166 33 283 12z m3994
-15 c17 -25 21 -68 7 -68 -19 0 -41 32 -41 61 0 35 13 37 34 7z m-3711 -34
c53 -3 97 -10 97 -15 0 -4 -26 -16 -57 -25 -52 -15 -75 -16 -218 -5 -205 16
-195 14 -167 30 25 13 168 30 217 25 17 -2 74 -6 128 -10z m239 -85 c43 -9 56
-15 48 -23 -14 -14 -410 -12 -455 2 -28 8 -25 10 40 20 141 23 252 23 367 1z
m293 -109 c25 -4 34 -9 25 -13 -26 -11 -63 -12 -130 -4 -61 8 -64 10 -45 24
20 15 36 14 150 -7z m799 -240 c20 -7 19 -9 -8 -19 -20 -8 -45 -9 -85 -1 -31
5 -67 11 -81 13 l-25 4 25 11 c26 12 135 7 174 -8z m249 -79 c60 -13 57 -24
-6 -19 -38 3 -64 11 -75 23 -16 17 -16 17 13 11 17 -4 47 -11 68 -15z m2508
-125 c15 -26 17 -37 8 -34 -15 5 -79 105 -79 123 0 14 43 -40 71 -89z m-2218
63 c22 -6 36 -13 33 -17 -9 -8 -156 -10 -156 -2 0 29 56 38 123 19z m-158 -63
c58 -13 59 -14 32 -25 -41 -16 -88 -14 -109 6 -39 35 -20 40 77 19z m375 -21
c30 -8 67 -15 83 -15 15 0 27 -4 27 -9 0 -17 -87 -24 -153 -12 -35 6 -78 11
-95 11 -35 0 -42 13 -14 29 25 15 88 13 152 -4z m3173 -282 c13 -25 67 -202
67 -218 0 -3 -9 -1 -20 5 -29 16 -49 61 -55 127 -3 32 -8 70 -10 86 -3 15 -2
27 0 27 2 0 11 -12 18 -27z m641 -89 c20 -5 26 -13 26 -33 0 -14 13 -63 28
-109 24 -69 26 -84 15 -94 -23 -18 -34 4 -44 84 -4 40 -8 76 -9 80 0 4 -25 24
-56 44 -58 38 -94 84 -94 118 0 15 12 7 54 -32 30 -28 66 -54 80 -58z m35 66
c41 -12 86 -52 111 -100 22 -44 62 -178 58 -192 -4 -11 -41 44 -97 145 -34 59
-78 110 -121 139 -23 15 -23 17 -5 17 11 1 36 -3 54 -9z m196 -103 c7 -18 19
-47 25 -64 7 -18 16 -30 21 -27 14 8 11 42 -6 74 -15 28 -14 30 5 30 34 0 53
-45 58 -134 2 -44 0 -99 -5 -121 l-9 -39 -26 34 c-14 19 -46 94 -70 167 -36
109 -40 130 -25 122 10 -6 24 -25 32 -42z m-347 5 c45 -34 80 -98 84 -152 2
-34 -1 -45 -12 -45 -11 0 -17 15 -20 53 -4 32 -12 57 -20 62 -65 36 -85 57
-88 89 -4 36 -2 36 56 -7z m-2892 13 c-36 -27 -202 -32 -286 -8 -24 7 -23 8
10 14 19 3 94 7 165 7 114 1 128 0 111 -13z m399 -24 c36 -17 65 -33 65 -37 0
-11 -111 2 -173 20 -48 15 -56 20 -46 32 19 23 84 16 154 -15z m2431 -48 c20
-47 17 -59 -7 -37 -22 19 -49 73 -49 95 0 20 42 -23 56 -58z m609 -25 c26 -59
66 -218 55 -218 -5 0 -22 16 -38 34 -31 35 -33 44 -45 189 -3 26 -3 47 0 47 2
0 15 -24 28 -52z m-2761 -32 c57 -15 43 -26 -33 -26 -43 0 -71 12 -71 32 0 12
48 9 104 -6z m258 -81 c26 -3 50 -10 53 -14 8 -13 -89 -21 -250 -20 -147 1
-295 14 -215 18 19 2 91 9 160 16 121 12 152 12 252 0z m1528 -229 l-9 -24
144 -7 c80 -4 151 -5 160 -2 51 20 -42 -93 -105 -127 -44 -24 -69 -30 -135
-33 -73 -3 -78 -3 -55 10 14 8 53 23 87 32 34 10 70 26 79 36 18 20 16 21 -46
41 -8 2 -25 -8 -37 -23 -28 -33 -56 -43 -193 -68 -58 -10 -113 -22 -122 -26
-24 -10 -23 12 0 24 11 6 54 19 97 31 91 24 130 43 139 66 4 9 5 18 4 20 -2 1
-30 -8 -63 -22 -70 -28 -142 -43 -131 -26 3 6 29 18 56 27 28 9 50 20 50 25 0
4 -17 12 -37 17 l-38 9 45 22 c25 12 62 22 82 22 35 0 37 -1 28 -24z m-210
-25 c0 -16 -24 -29 -67 -36 -35 -6 -43 -4 -43 9 0 9 10 18 23 20 55 12 63 14
75 15 6 0 12 -3 12 -8z m1200 -223 c0 -15 -18 -74 -39 -130 -21 -57 -41 -114
-43 -128 l-4 -25 -13 30 c-14 34 -13 44 37 197 31 99 39 114 50 100 6 -9 12
-28 12 -44z m2573 35 c28 -8 28 -9 10 -30 -24 -28 -111 -39 -109 -14 1 13 -12
17 -72 19 -65 3 -74 1 -96 -21 -24 -24 -25 -24 -53 -6 -15 10 -50 27 -78 36
l-50 18 40 7 c52 9 372 2 408 -9z"
        />
        <path
          d="M7596 2406 c39 -13 86 -32 104 -41 60 -31 140 -54 263 -76 97 -17
144 -31 229 -70 119 -56 192 -69 376 -69 89 0 125 -6 275 -45 321 -83 1222
-273 1235 -261 24 25 -187 86 -488 141 -107 20 -298 56 -425 80 -126 25 -351
67 -500 95 -304 57 -349 68 -455 114 -91 40 -145 53 -275 66 -69 6 -132 21
-203 45 -57 20 -126 38 -155 41 l-52 4 71 -24z"
        />
        <path
          d="M1759 5272 c16 -30 44 -49 110 -76 79 -32 85 -32 99 -1 15 31 28 31
110 -2 127 -50 211 -113 411 -307 80 -78 117 -109 105 -88 -34 60 -123 150
-282 284 l-156 132 39 9 c22 5 99 10 170 11 148 1 158 -2 320 -99 83 -49 135
-73 135 -62 0 3 -23 20 -51 38 -46 29 -89 79 -89 103 0 17 85 -3 167 -39 105
-45 151 -48 74 -4 -30 17 -71 43 -91 58 l-36 26 -385 0 c-342 0 -391 -2 -452
-18 l-68 -19 -52 36 c-58 40 -94 48 -78 18z"
        />
        <path
          d="M3070 5253 c-61 -6 -150 -25 -150 -33 0 -4 10 -10 23 -14 12 -3 47
-26 77 -51 30 -25 58 -45 62 -45 17 0 7 28 -17 50 -14 13 -24 31 -23 39 3 13
23 15 132 12 171 -4 206 -16 348 -124 115 -88 148 -104 148 -72 0 20 34 19
102 -3 175 -58 277 -177 399 -462 34 -80 64 -147 66 -150 3 -3 2 15 -2 40 -12
74 -62 193 -122 287 -101 159 -131 208 -128 211 7 8 89 -42 127 -76 57 -51
104 -125 187 -291 40 -79 74 -141 77 -138 3 2 -20 60 -50 128 -55 126 -91 229
-78 229 29 0 77 -49 116 -117 49 -86 92 -143 109 -143 6 0 -1 13 -16 28 -15
15 -36 57 -48 92 -11 36 -33 78 -47 94 -15 16 -77 52 -138 81 -101 48 -164 96
-164 126 0 9 49 -9 153 -55 63 -29 73 -21 26 19 -76 64 -177 91 -276 75 -44
-8 -60 -7 -72 4 -9 8 -47 33 -86 56 -63 39 -72 41 -100 31 -49 -17 -74 -13
-114 18 -56 44 -159 98 -231 120 -63 19 -205 43 -238 39 -9 0 -33 -3 -52 -5z"
        />
        <path
          d="M3097 5173 c-14 -13 -6 -22 36 -42 72 -35 91 -23 36 23 -30 25 -59
33 -72 19z"
        />
        <path
          d="M67 5093 c-26 -26 15 -74 65 -76 30 -2 33 1 30 23 -4 42 -70 79 -95
53z"
        />
        <path
          d="M325 5090 c-3 -5 11 -24 31 -42 49 -44 64 -52 75 -41 5 5 -9 23 -34
44 -55 46 -64 51 -72 39z"
        />
        <path
          d="M2888 4954 c23 -27 71 -80 107 -119 l66 -70 -50 80 c-54 87 -102 139
-141 150 -21 7 -20 2 18 -41z"
        />
        <path
          d="M2 4968 c3 -7 26 -18 52 -25 27 -7 51 -15 54 -18 12 -12 -22 -23 -55
-18 -26 4 -33 2 -33 -11 0 -20 7 -20 81 -1 33 9 64 13 69 10 24 -15 7 16 -17
33 -54 37 -160 58 -151 30z"
        />
        <path
          d="M2262 4909 c21 -24 72 -87 115 -139 94 -118 113 -136 83 -85 -46 79
-85 131 -139 188 -69 73 -112 99 -59 36z"
        />
        <path
          d="M4300 4936 c0 -19 24 -47 51 -61 17 -9 75 -25 129 -35 55 -10 109
-24 120 -30 34 -18 43 -7 20 23 -28 35 -8 35 39 -2 67 -52 101 -65 181 -66 68
-1 81 -5 135 -38 55 -34 115 -49 115 -29 0 15 -239 129 -339 162 -52 18 -137
40 -190 50 -110 21 -261 36 -261 26z"
        />
        <path
          d="M2713 4883 c8 -10 36 -54 62 -98 49 -84 82 -125 98 -125 6 0 1 10
-10 23 -11 12 -39 58 -62 102 -48 90 -67 115 -89 115 -13 0 -13 -2 1 -17z"
        />
        <path
          d="M2180 4873 c0 -17 37 -64 78 -99 41 -34 53 -32 32 6 -11 21 -110 105
-110 93z"
        />
        <path
          d="M3110 4805 c8 -11 39 -47 68 -80 29 -33 70 -85 90 -115 25 -37 38
-50 40 -38 5 24 -23 73 -71 125 -46 50 -150 138 -127 108z"
        />
        <path
          d="M3277 4768 c11 -13 46 -63 78 -113 59 -94 92 -135 107 -135 5 0 -2
15 -15 33 -13 17 -43 65 -67 104 -41 71 -94 133 -114 133 -5 0 0 -10 11 -22z"
        />
        <path
          d="M3430 4775 c0 -32 187 -325 207 -325 6 0 -58 115 -105 190 -43 68
-102 146 -102 135z"
        />
        <path
          d="M2929 4725 c21 -44 101 -154 127 -176 41 -34 -46 103 -111 176 l-36
40 20 -40z"
        />
        <path
          d="M3601 4685 c7 -16 24 -47 37 -68 13 -22 42 -77 64 -123 22 -46 44
-84 49 -84 13 0 14 -2 -50 132 -33 68 -71 134 -86 148 l-27 25 13 -30z"
        />
        <path
          d="M2569 4645 c35 -62 112 -167 119 -161 2 2 -10 30 -26 62 -16 33 -32
71 -36 87 -3 15 -12 27 -20 27 -7 0 -26 10 -42 23 l-29 22 34 -60z"
        />
        <path
          d="M5170 4690 c0 -16 42 -43 87 -54 43 -11 51 -18 80 -67 53 -91 82
-129 98 -129 13 0 11 7 -8 33 -14 17 -32 46 -41 64 -18 35 -22 108 -6 118 25
15 6 21 -39 12 -41 -9 -59 -7 -110 10 -34 11 -61 17 -61 13z"
        />
        <path
          d="M3730 4652 c0 -6 4 -13 9 -16 5 -3 28 -52 52 -108 48 -114 81 -177
86 -161 5 14 -53 170 -82 218 -25 44 -65 85 -65 67z"
        />
        <path
          d="M5420 4634 c0 -26 59 -157 85 -189 22 -26 25 -28 25 -9 0 11 -5 25
-12 32 -6 6 -8 15 -4 19 4 5 18 -5 31 -22 37 -45 38 -25 4 44 -47 93 -129 173
-129 125z"
        />
        <path
          d="M2799 4581 c12 -16 39 -67 60 -114 36 -78 56 -106 67 -95 5 6 -55
144 -84 190 -19 31 -40 48 -58 48 -3 0 4 -13 15 -29z"
        />
        <path
          d="M5584 4547 c12 -23 30 -62 39 -86 10 -24 20 -42 22 -39 2 2 -3 41
-12 86 -15 78 -17 82 -43 82 l-27 0 21 -43z"
        />
        <path
          d="M2720 4575 c0 -4 15 -39 34 -78 30 -66 86 -136 86 -109 0 6 -5 14
-10 17 -6 4 -28 42 -50 85 -34 69 -60 105 -60 85z"
        />
        <path
          d="M5670 4552 c0 -5 15 -15 33 -24 65 -32 116 -87 158 -174 39 -78 59
-98 74 -74 3 6 17 10 29 10 17 0 46 -27 106 -96 106 -122 431 -407 446 -391 5
5 -116 121 -227 217 -91 79 -163 160 -198 222 -16 30 -35 49 -50 53 -14 4 -38
15 -53 27 -22 15 -37 18 -63 14 -32 -6 -35 -5 -35 16 -1 60 -68 156 -131 188
-39 20 -89 27 -89 12z"
        />
        <path
          d="M3889 4523 c12 -15 41 -88 65 -162 25 -73 50 -139 56 -145 20 -18 4
46 -48 194 -41 117 -50 135 -70 138 l-24 4 21 -29z"
        />
        <path
          d="M4510 4544 c0 -3 8 -18 19 -32 10 -15 37 -63 59 -107 39 -78 71 -128
72 -113 0 12 -76 186 -94 213 -16 24 -56 52 -56 39z"
        />
        <path
          d="M3111 4465 c43 -98 102 -173 75 -97 -21 60 -41 94 -70 122 l-27 24
22 -49z"
        />
        <path
          d="M4020 4471 c0 -5 4 -13 10 -16 5 -3 21 -45 35 -93 36 -123 54 -169
69 -181 15 -13 -33 181 -60 242 -17 36 -54 70 -54 48z"
        />
        <path
          d="M4677 4363 c22 -63 47 -103 56 -92 6 6 -64 159 -73 159 -4 0 3 -30
17 -67z"
        />
        <path
          d="M4770 4407 c0 -15 56 -172 67 -186 2 -2 5 -2 8 1 3 3 -4 34 -15 69
-24 75 -59 143 -60 116z"
        />
        <path
          d="M4545 4335 c16 -92 58 -205 76 -205 6 0 6 7 1 18 -5 9 -22 60 -37
112 -28 101 -52 145 -40 75z"
        />
        <path
          d="M2630 4324 c0 -15 70 -121 110 -164 l31 -35 -33 63 c-33 63 -107 156
-108 136z"
        />
        <path
          d="M3485 4278 c9 -34 76 -170 81 -165 2 2 -5 30 -16 61 -27 80 -83 169
-65 104z"
        />
        <path
          d="M5166 4235 c11 -63 44 -118 61 -101 8 7 -16 64 -44 106 l-24 35 7
-40z"
        />
        <path
          d="M5400 4255 c0 -11 10 -36 22 -55 11 -19 24 -46 28 -60 3 -13 9 -20
12 -14 9 13 -26 112 -46 132 -15 15 -16 15 -16 -3z"
        />
        <path
          d="M5330 4214 c0 -18 36 -118 58 -160 17 -31 21 -34 22 -17 0 12 -9 46
-20 75 -21 55 -60 121 -60 102z"
        />
        <path
          d="M565 4169 c-65 -16 -69 -32 -5 -25 30 3 88 9 128 12 45 4 68 10 60
15 -17 11 -132 10 -183 -2z"
        />
        <path
          d="M5738 4093 c3 -73 18 -113 43 -113 29 0 9 117 -29 168 -16 21 -17 19
-14 -55z"
        />
        <path
          d="M4210 4073 c0 -60 26 -174 52 -227 23 -49 41 -61 34 -23 -3 12 -8 51
-11 86 -7 67 -31 142 -59 182 -16 23 -16 23 -16 -18z"
        />
        <path
          d="M5190 4090 c0 -19 42 -120 49 -120 8 0 -4 66 -20 108 -8 22 -29 31
-29 12z"
        />
        <path
          d="M3858 4085 c-7 -12 26 -123 41 -138 18 -18 13 29 -8 76 -10 23 -20
49 -23 57 -2 7 -7 10 -10 5z"
        />
        <path
          d="M6350 4019 c0 -13 67 -44 148 -69 65 -19 80 -28 129 -81 31 -32 59
-59 64 -59 4 0 9 10 11 23 3 19 10 22 52 25 58 4 122 -18 167 -56 17 -14 62
-63 101 -109 38 -46 72 -82 75 -79 11 10 -62 118 -116 173 -86 88 -204 130
-296 104 -37 -10 -41 -9 -70 18 -32 31 -74 54 -139 78 -82 30 -126 41 -126 32z"
        />
        <path
          d="M5513 3986 c14 -37 59 -96 72 -96 20 0 20 2 -16 60 -17 27 -38 50
-46 50 -9 0 -13 -6 -10 -14z"
        />
        <path
          d="M3805 3950 c10 -48 44 -127 61 -141 19 -15 19 -16 -17 76 -34 90 -55
121 -44 65z"
        />
        <path
          d="M4510 3789 c0 -20 48 -120 67 -141 32 -35 33 -17 2 45 -36 70 -69
117 -69 96z"
        />
        <path
          d="M6071 3702 c-1 -32 19 -111 30 -118 12 -7 0 72 -17 111 -11 27 -12
28 -13 7z"
        />
        <path
          d="M4930 3687 c0 -4 9 -26 21 -48 42 -84 2 -117 -43 -34 -27 50 -52 75
-75 75 -19 0 -16 -18 9 -71 12 -26 35 -55 51 -64 16 -10 58 -57 94 -107 56
-75 102 -118 128 -118 4 0 -14 24 -40 53 -47 51 -47 52 -23 55 l25 3 -27 29
c-18 20 -34 54 -45 99 -10 44 -27 81 -45 102 -16 19 -29 30 -30 26z"
        />
        <path
          d="M6676 3620 c24 -83 46 -129 51 -107 6 23 -23 106 -47 137 l-20 25 16
-55z"
        />
        <path
          d="M5420 3627 c0 -11 39 -93 53 -112 28 -37 29 -7 1 44 -24 46 -54 83
-54 68z"
        />
        <path
          d="M5715 3589 c9 -47 37 -102 54 -107 32 -10 -16 125 -48 136 -10 3 -12
-3 -6 -29z"
        />
        <path
          d="M6250 3570 c0 -44 75 -248 87 -236 8 8 -48 190 -68 221 -10 17 -19
23 -19 15z"
        />
        <path
          d="M7021 3538 c9 -51 16 -70 48 -126 26 -44 43 -55 34 -22 -9 36 6 21
32 -30 14 -28 30 -48 36 -44 7 4 5 13 -4 27 -7 12 -26 56 -42 97 -26 69 -78
140 -103 140 -6 0 -6 -16 -1 -42z"
        />
        <path
          d="M5245 3515 c62 -113 229 -335 290 -385 47 -39 -34 81 -169 250 -56
69 -112 139 -125 156 -20 25 -19 22 4 -21z"
        />
        <path
          d="M5225 3374 c28 -27 78 -82 113 -121 35 -40 73 -78 85 -84 l22 -12
-20 24 c-76 91 -188 207 -216 223 -30 17 -28 14 16 -30z"
        />
        <path
          d="M5951 3367 c31 -59 54 -83 63 -69 6 10 -64 114 -81 119 -6 2 2 -20
18 -50z"
        />
        <path
          d="M6160 3400 c0 -14 60 -113 66 -107 2 1 -2 20 -8 40 -12 38 -58 92
-58 67z"
        />
        <path
          d="M6926 3323 c40 -182 70 -260 103 -271 9 -3 8 4 -3 27 -9 17 -27 70
-40 118 -29 106 -41 139 -56 148 -7 5 -9 -2 -4 -22z"
        />
        <path
          d="M6870 3282 c0 -53 18 -128 34 -141 17 -14 0 122 -19 158 -14 26 -14
26 -15 -17z"
        />
        <path
          d="M7030 3223 c0 -31 44 -182 64 -221 14 -26 25 -43 26 -38 0 11 -54
200 -69 239 -10 25 -21 36 -21 20z"
        />
        <path
          d="M7134 3150 c3 -36 10 -81 16 -100 10 -35 68 -136 74 -129 2 2 -19 69
-46 149 -49 144 -49 145 -44 80z"
        />
        <path
          d="M6595 3073 c3 -10 13 -54 20 -99 13 -78 34 -136 55 -149 18 -11 -22
169 -50 225 -20 40 -38 56 -25 23z"
        />
        <path
          d="M7474 2968 c18 -61 55 -129 56 -103 0 20 -55 145 -64 145 -3 0 1 -19
8 -42z"
        />
        <path
          d="M8300 2965 c0 -21 94 -141 122 -156 18 -10 -36 87 -69 123 -19 22
-53 42 -53 33z"
        />
        <path
          d="M7865 2873 c4 -10 22 -61 40 -113 33 -99 68 -174 84 -185 15 -9 -65
222 -94 271 -26 44 -44 60 -30 27z"
        />
        <path
          d="M7970 2867 c0 -43 103 -287 116 -274 10 9 -72 229 -101 270 -13 18
-15 19 -15 4z"
        />
        <path
          d="M8240 2682 c0 -41 13 -80 35 -108 l24 -29 -6 45 c-8 66 -53 144 -53
92z"
        />
        <path
          d="M8325 2650 c16 -78 49 -160 77 -195 17 -20 32 -34 34 -32 5 4 -103
247 -113 257 -3 3 -2 -11 2 -30z"
        />
        <path
          d="M8560 2590 c0 -58 14 -105 45 -155 l26 -40 -6 45 c-13 97 -27 150
-45 172 l-19 23 -1 -45z"
        />
        <path
          d="M8540 2437 c0 -16 5 -38 11 -50 14 -27 60 -77 71 -77 9 0 -41 103
-66 135 -15 19 -15 19 -16 -8z"
        />
        <path
          d="M9654 1655 c-80 -18 -178 -58 -220 -90 -45 -34 -43 -47 4 -25 20 10
52 22 71 25 l35 7 -42 -47 c-36 -41 -53 -75 -37 -75 2 0 22 19 44 42 48 52 66
44 23 -10 -35 -43 -58 -89 -50 -98 6 -5 106 96 121 123 6 9 3 21 -8 33 -16 18
-15 19 14 34 17 9 41 14 54 11 17 -5 32 3 61 29 58 56 41 65 -70 41z"
        />
        <path
          d="M10187 1476 c-15 -62 -27 -132 -27 -155 0 -39 -2 -41 -30 -41 l-30 0
0 -100 c0 -92 8 -118 26 -89 4 7 23 -7 49 -36 58 -62 80 -59 106 15 29 80 32
96 21 117 -10 17 -11 17 -17 -2 -13 -40 -28 -64 -43 -70 -12 -4 -13 2 -7 42
11 73 11 142 0 145 -5 2 -13 -21 -17 -51 -3 -29 -13 -61 -22 -69 -15 -15 -16
-15 -16 3 1 58 40 305 54 337 16 38 13 68 -8 68 -8 0 -23 -46 -39 -114z"
        />
        <path
          d="M9551 1380 c-61 -87 -91 -157 -91 -212 0 -47 12 -63 24 -32 3 9 6 30
6 47 1 44 30 102 84 167 44 52 54 79 31 87 -6 2 -30 -24 -54 -57z"
        />
        <path
          d="M9846 1195 c-17 -34 -25 -62 -21 -75 10 -30 22 -24 45 20 20 39 28
110 12 110 -4 0 -20 -25 -36 -55z"
        />
        <path
          d="M10329 1113 c-17 -45 -23 -93 -11 -93 20 0 42 44 42 82 0 44 -16 50
-31 11z"
        />
      </g>
    </Svg>
  );
};

export default Quill;
